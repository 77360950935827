import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ae648ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "load-more-button" }
const _hoisted_2 = {
  key: 1,
  class: "loading-indicator"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextButton = _resolveComponent("TextButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_TextButton, {
          key: 0,
          icon: require('icons/load-more.svg'),
          "disabled-icon": require('icons/load-more-grey.svg'),
          enabled: _ctx.enabled,
          text: _ctx.$t('common.loadMoreText'),
          onOnClick: _ctx.click
        }, null, 8, ["icon", "disabled-icon", "enabled", "text", "onOnClick"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2))
  ]))
}