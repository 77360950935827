
// @ is an alias to /sr
import { defineComponent, onMounted, reactive, ref, toRefs, inject } from "vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import LoadMore from "@/components/atomics/LoadMore.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import Popup from "@/components/popups/Popup.vue";
import MemberTile from "./MemberTile.vue";
import { ContactRepository, RepositoryFactory } from "@/lib/https";
import { useForm } from "vee-validate";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "Contact",
  components: {
    FlatButton,
    LoadMore,
    ValidationTextField,
    Popup,
    MemberTile,
    AfterLoginPageLayout,
  },
  setup() {
    const isMobile = inject("isMobile");
    const state = reactive({
      isOverflow: false,
      isShowDeletePopup: null,
      isShowHidePopup: null,
      isShowEditPopup: false,
      isShowAddPopup: false,
      contacts: [] as any[],
      contactPermissions: {
        role: 3,
        canCreate: false,
        bookmark: 0
      },
    });

    const store = useStore();
    const { t } = useI18n();
    const showSuccessNotification = (text: string) => {
      store.commit("notification/showSuccessNotification", text);
    };

    const showErrorNotification = (text: string) => {
      store.commit("notification/showErrorNotification", text);
    };

    // search, get Contact List
    const searchKeyword = ref("");
    const searchKeywordTextField = ref("");
    let delay: ReturnType<typeof setTimeout>;

    let page = 1;
    let total = ref(0);
    let isAllContacts = ref(false);

    // edit Contact
    const contactForm = useForm({
      initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        department: "",
      },
    });

    const {
      getContactList,
      createContact,
      unHideContact,
      editContact,
      hideContact,
      deleteContact,
    } = contactMethods();
    const { formHandle } = useFormHandler(contactForm, editContact);
    const addContact = useFormHandler(contactForm, createContact);

    const submitEdit = async () => {
      await formHandle()
        .then((response) => {
          if (response) {
            const index = state.contacts.findIndex(
              (item: any) => item.id === response.id
            );
            state.contacts[index] = { ...response };
            hideContactInfoPopup();
          }
          showSuccessNotification(t("notification.updateSuccess"));
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
    };

    const submitCreate = async () => {
      await addContact
        .formHandle()
        .then((response) => {
          if (response) {
            state.contacts.push(response);
            total.value = total.value + 1;
            state.isShowAddPopup = false;
            showSuccessNotification(t("notification.createSuccess"));
          }
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
    };

    const onDelete = async (contact: any) => {
      await deleteContact(contact.id)
        .then((response) => {
          if (response) {
            state.contacts.splice(state.contacts.indexOf(contact), 1);
            total.value = total.value - 1;
            state.isShowDeletePopup = null;
            showSuccessNotification(t("notification.deleteSuccess"));
          }
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
    };

    const onHide = async (contact: any) => {
      if (await hideContact(contact.id)) {
        if (!isAllContacts.value)
          state.contacts.splice(state.contacts.indexOf(contact), 1);
        contact.isHidden = true;
        state.isShowHidePopup = null;
      }
    };

    const onUnHide = async (contact: any) => {
      if (await unHideContact(contact.id)) {
        contact.isHidden = false;
        state.isShowHidePopup = null;
      }
    };

    const onShowEditContact = (contact: any) => {
      const cloneObject = JSON.parse(JSON.stringify(contact));
      contactForm.setValues(cloneObject);
      state.isShowEditPopup = true;
    };

    const hideContactInfoPopup = () => {
      contactForm.resetForm({
        firstName: " ",
        lastName: " ",
        email: " ",
        companyName: "",
        department: "",
      } as any);
      state.isShowEditPopup = false;
      state.isShowAddPopup = false;
    };

    const PAGE_SIZE_DEFAULT = 20;

    const loadMoreContacts = async (onScroll: boolean) => {
      const currentPage = Math.ceil(state.contacts.length / PAGE_SIZE_DEFAULT);
      const isNextPage = state.contacts.length % PAGE_SIZE_DEFAULT === 0;
      if (isNextPage) page = currentPage + 1;
      else page = currentPage;

      const moreContacts = await getContactList(
          isAllContacts.value,
          page,
          PAGE_SIZE_DEFAULT,
          searchKeyword.value,
          state.contactPermissions.bookmark as any
      );

      if (moreContacts.pagination.totalRecords > total.value) {
          total.value = moreContacts.pagination.totalRecords;
        } else if (
          moreContacts.data.length % PAGE_SIZE_DEFAULT !== 0 &&
          state.contacts.length >= moreContacts.pagination.totalRecords && !onScroll
        )
          showErrorNotification(t("errors.errorNoNewRecord"));
        for (const index in moreContacts.data) {
          const isContain = state.contacts.some(
            (member: any) => member.id === moreContacts.data[index].id
          );
          if (!isContain) state.contacts.push(moreContacts.data[index]);
        }

        state.contactPermissions.bookmark = moreContacts.pagination.bookmark || 0;
    };

    const search = (searchString: any) => {
      if (delay) clearTimeout(delay);
      delay = setTimeout(async () => {
        searchKeyword.value = searchString;
        const payload = await getContactList(
          isAllContacts.value,
          (page = 1),
          PAGE_SIZE_DEFAULT,
          searchKeyword.value
        );
        state.contacts = payload.data;
        total.value = payload.pagination.totalRecords ?? 0;
        state.contactPermissions.bookmark = payload.pagination.bookmark || 0;

      }, 300);
    };

    const clearSearch = () => {
      if (searchKeyword.value != "") {
        searchKeyword.value = "";
        searchKeywordTextField.value = "";
        search(searchKeyword.value);
      }
    };

    onMounted(async () => {
      const payload = await getContactList(
        isAllContacts.value,
        1,
        PAGE_SIZE_DEFAULT
      );
      state.contacts = payload.data;
      state.contactPermissions = payload.pagination;
      total.value = payload.pagination.totalRecords ?? 0;
      state.contactPermissions.bookmark = payload.pagination.bookmark || 0;

    });

    return {
      ...toRefs(state),
      submitEdit,
      onShowEditContact,
      searchKeyword,
      searchKeywordTextField,
      search,
      isAllContacts,
      onHide,
      onDelete,
      submitCreate,
      onUnHide,
      total,
      hideContactInfoPopup,
      loadMoreContacts,
      clearSearch,
      isMobile,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.onUpdateLayout();
    });
  },

  methods: {
    onUpdateLayout() {
      const height = window.innerHeight;
      const popupContainer = this.$refs.popup as HTMLElement;
      const clientHieght = popupContainer?.clientHeight as number;
      if (clientHieght >= height - (this.isMobile ? 160 : 330)) {
        this.isOverflow = true;
      } else {
        this.isOverflow = false;
      }
    },
  },
});

const contactMethods = () => {
  const {
    getContactList,
    editContact,
    hideContact,
    deleteContact,
    unHideContact,
    createContact,
  } = RepositoryFactory.getRepository<ContactRepository>(ContactRepository);

  return {
    getContactList,
    editContact,
    hideContact,
    deleteContact,
    unHideContact,
    createContact,
  };
};
