import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e3bd46f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "leading" }
const _hoisted_2 = { class: "contact-info" }
const _hoisted_3 = { class: "info-text" }
const _hoisted_4 = { class: "row row-center" }
const _hoisted_5 = { class: "mr-1 long-name" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "mail" }
const _hoisted_8 = { class: "row group-company" }
const _hoisted_9 = { class: "ml-1 group-name" }
const _hoisted_10 = {
  key: 0,
  class: "chip-content ml-1"
}
const _hoisted_11 = { class: "option" }
const _hoisted_12 = { class: "option" }
const _hoisted_13 = { class: "option delete" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvatarCircle = _resolveComponent("AvatarCircle")!
  const _component_Vue3Popper = _resolveComponent("Vue3Popper")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_MenuOptionPopup = _resolveComponent("MenuOptionPopup")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["user-tile", { 'bg-grey': _ctx.isSelected }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_AvatarCircle, {
          avatar: require('icons/contact-outline.svg'),
          alt: "avatar",
          class: "avatar"
        }, null, 8, ["avatar"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.contact.lastName + " " + _ctx.contact.firstName), 1),
            (_ctx.contact.isHidden)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "ml-1",
                  src: require('icons/hide-black.svg'),
                  alt: "hide"
                }, null, 8, _hoisted_6))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_Vue3Popper, {
            content: _ctx.contact.email
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.contact.email), 1)
            ]),
            _: 1
          }, 8, ["content"])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.contact.department), 1),
        (_ctx.contact.companyName.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("p", null, _toDisplayString(_ctx.contact.companyName), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_MenuOptionPopup, null, {
      target: _withCtx(({ showMenuOption }) => [
        _createVNode(_component_TextButton, {
          icon: require('icons/more.svg'),
          "is-show-text": false,
          onOnClick: showMenuOption,
          class: _normalizeClass(["more-btn", {
            'is-active': _ctx.isShowMenu,
          }])
        }, null, 8, ["icon", "onOnClick", "class"])
      ]),
      options: _withCtx(() => [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_TextButton, {
            onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showEditPopup(_ctx.contact))),
            icon: require('icons/edit.svg'),
            text: _ctx.$t('contact.edit'),
            enabled: _ctx.contact.canUpdate,
            "disabled-icon": require('icons/edit-grey.svg')
          }, null, 8, ["icon", "text", "enabled", "disabled-icon"])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_TextButton, {
            onOnClick: _ctx.showHidePopup,
            icon: 
              !_ctx.contact.isHidden
                ? require('icons/hide-primary.svg')
                : require('icons/primary-eye.svg')
            ,
            text: !_ctx.contact.isHidden ? _ctx.$t('contact.hide') : _ctx.$t('contact.show')
          }, null, 8, ["onOnClick", "icon", "text"])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_TextButton, {
            class: "delete-btn",
            onOnClick: _ctx.showDeletePopup,
            icon: require('icons/delete.svg'),
            text: _ctx.$t('contact.delete'),
            enabled: _ctx.contact.canDelete,
            "disabled-icon": require('icons/delete-grey.svg')
          }, null, 8, ["onOnClick", "icon", "text", "enabled", "disabled-icon"])
        ])
      ]),
      _: 1
    })
  ], 2))
}