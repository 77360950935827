
import { defineComponent, reactive, toRefs } from "vue";
import TextButton from "@/components/atomics/TextButton.vue";
export default defineComponent({
  components: {
    TextButton,
  },
  props: {
    enabled: { type: Boolean, default: true },
    onLoadMore: { type: Function },
  },
  setup(props, ctx) {
    const state = reactive({
      isLoading: false,
    });

    const click = async () => {
      if (props.onLoadMore) {
        state.isLoading = true;
        ctx.emit("onLoading", true);
        await props.onLoadMore();
        ctx.emit("onLoading", false);
        state.isLoading = false;
      } else {
        ctx.emit("onClick", null);
      }
    };
    return {
      ...toRefs(state),
      click,
    };
  },
});
