
import { defineComponent, watch } from "vue";
import MenuOptionPopup from "@/components/atomics/MenuOptionPopup.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import Vue3Popper from "@/components/atomics/Vue3Popper.vue";

export default defineComponent({
  name: "MemberTile",
  components: {
    MenuOptionPopup,
    TextButton,
    AvatarCircle,
    Vue3Popper,
  },
  props: {
    contact: { type: Object },
    isSelected: { type: Boolean, default: false },
  },

  methods: {
    showEditPopup(contact: any) {
      this.$emit("onShowEditPopup", contact);
    },
    showDeletePopup() {
      this.$emit("onShowDeletePopup");
    },
    showHidePopup() {
      this.$emit("onShowHidePopup");
    },
  },
});
